// Due to the different error formats returned by the server, this function
// tries to parse the error message from the server.
// TODO: figure out why the API returns different formats.
// The user delete action is returning a different format than the 401 authorized response, for example.
const parseServerErrorMessage = (error: any): string | undefined => {
  const errorData = error.data || error._data
  return errorData?.errors?.[0]?.detail || errorData?.errors?.[0] || errorData?.error
}

export default parseServerErrorMessage

// Example error response for /api/v1/contracts POST:
// (`errorData?.errors?.[0]?.detail` solves this case)
//
// {
//   "status": "422",
//   "source": {
//     "pointer": ""
//   },
//   "title": "Unprocessable Entity",
//   "detail": "Er is al een actief contract voor deze stage.",
//   "code": "er_is_al_een_actief_contract_voor_deze_stage"
// }
