import type { ObjectSchema } from 'yup'

type ServerError = {
  status: string
  source: {
    pointer: string
  }
  title: string
  detail: string
  code: string
}

type ErrorResponse = {
  errors: ServerError[]
}

// Flattens a Zod schema into a list of field paths
function flattenYupSchema(schema: any, path: string = ''): string[] {
  // For a simple schema, this seems to work. Need to test with nested schemas
  if (schema.fields) {
    return Object.keys(schema.fields)
  }
  // TODO: Not sure if the code below still works:

  if (typeof schema.shape === 'undefined') {
    return [path]
  }

  return Object.keys(schema.shape).flatMap(key =>
    flattenYupSchema(schema.shape[key], path ? `${path}.${key}` : key),
  )
}

// Map the server error response to a more useful structure that is mapped based on the form fields
// defined in the schema.
export function tryMapFieldErrors(
  serverError: ErrorResponse,
  yupSchema: ObjectSchema<any>,
) {
  if (serverError.errors) {
    const validFieldNames = flattenYupSchema(yupSchema)

    const fieldErrors: Record<string, string> = {}

    serverError.errors.forEach((error) => {
      if (!error.source) {
        // In this case, please make sure the API returns an error pointer.
        console.error(`Received an error without an error pointer.`, error)
        return undefined
      }

      const pointer = error.source.pointer // /data/attributes/name
      const keyPath = pointer.split('/') // ['', 'data', 'attributes', 'name']
      // TODO: the backend doesn't return fields with a nested structure, which
      // doesn't always match the validFieldNames.
      const attributeName = keyPath.pop() // 'name'

      if (attributeName && validFieldNames.includes(attributeName)) {
        fieldErrors[attributeName] = error.detail
      }
      else if (validFieldNames.includes('id')) {
        // Hacky solution for generic errors.
        console.error(`Received an error with an unknown pointer`, pointer)
        fieldErrors[`id`] = error.detail
      }
    })

    return fieldErrors
  }
  return undefined
}
