import type { ObjectSchema } from 'yup'

type SetFieldError = (
  // TODO: just use string for now, vee-validate is using Path<TValues>
  field: any, // Path<TValues>,
  message: string | string[] | undefined,
) => void

// TODO: refactor this so that it returns a function that handles each error, while keeping the arguments with the schema etc in
// the wrapper function. This way it would match the other form and validation composables.
// Basically acting more like a function factory instead of the error handler function itself as it is now.
//
// The yupSchema argument is optional to also be able to use this composable for server responses that aren't used with form,
// like DELETE API calls.
export const useServerErrorHandler = (
  yupSchema?: ObjectSchema<any>,
  setFieldError?: SetFieldError,
  prefix: string = '',
) => {
  const { t } = useI18n({ useScope: 'global' })
  const { toast } = useErrorToaster()

  function handleServerError(
    error: any,
    // TODO: This is a quickfix to support usage in handleServerError.
    _yupSchema: ObjectSchema<any> | undefined = undefined,
  ) {
    console.error(`handleError`, error)
    const schema = _yupSchema || yupSchema

    // Check if the error is a validation error
    if (error?.statusCode === 422 && schema && setFieldError) {
      const data = error.response._data
      // console.log(`data`, data)
      const fieldErrors = tryMapFieldErrors(data, schema)

      const fieldCount = Object.keys(fieldErrors || {}).length
      // console.log(`fieldCount`, fieldCount)
      if (fieldErrors && fieldCount > 0) {
        for (const fieldName in fieldErrors) {
          // console.log(`Field ${fieldName}`)
          const prefixedFieldName = prefix + fieldName
          // TODO: figure out why the API returns 'null' for the details of an error (fieldErrors[fieldName]) sometimes.
          // This happens for the non profits signup controller, for example, when no address is provided.
          // TODO: also localized the fallback error msg.
          const errorMsg = fieldErrors[fieldName] || 'Dit veld is verplicht'
          // console.log(`Field ${fieldName}`, prefixedFieldName, errorMsg)
          setFieldError(prefixedFieldName, errorMsg)
        }
        document.documentElement.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      // Example: when passwords don't match on signup controller, in which case the pointer is ''
      // but the `detail` attribute contains the error message.
      if (data.errors.length && data.errors[0].detail) {
        toast({
          title: t('errors.somethingWentWrong'),
          message: data.errors[0].detail,
        })
        return
      }
    }

    const serverMessage = parseServerErrorMessage(error)

    if (error?.statusCode === 401) {
      // Unauthorized: "authentication is required and has failed or has not yet been provided"
      toast({
        title: t('errors.notAuthorized'),
        message: serverMessage || t('errors.youDontHavePermissions'),
      })
    }
    else if (error?.statusCode === 403) {
      // Forbidden: "the server understands the request but refuses to fulfill it"
      toast({
        title: t('errors.forbidden'),
        message: serverMessage || t('errors.youDontHavePermissions'),
      })
    }
    else if (error?.statusCode === 404) {
      toast({
        title: t('errors.notFound'),
        message: serverMessage || t('errors.notFoundDescription'),
      })
    }
    else {
      // Some other error code
      toast({
        title: t('errors.oops'),
        message: serverMessage || t('errors.somethingWentWrong'),
      })
    }
  }

  return { handleServerError }
}
